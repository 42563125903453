import "./App.css";
import { Routes, Route } from "react-router-dom";
import NavBar from "./components/NavBar";
import MainContentHome from "./components/MainContentHome";
import Footer from "./components/Footer";
import Products from "./components/Products";
import About from "./components/About";
import Contact from "./components/Contact";
import SearchPage from "./components/SearchPage";

const App = () => {
  return (
    <div style={{ width: "100vw" }}>
      <NavBar />
      <Routes>
        <Route path="/" element={<MainContentHome />}></Route>
        <Route path="/vorur" element={<Products />}></Route>
        <Route path="/umokkur" element={<About />}></Route>
        <Route path="/hafdusamband" element={<Contact />}></Route>
        <Route path="/voruleit" element={<SearchPage />}></Route>
      </Routes>
      <Footer />
    </div>
  );
};

export default App;
