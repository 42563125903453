import React from "react";
import "./style.css";
import { useNavigate } from "react-router-dom";
import Categories from "../CategoriesList/index";
import PulseLoader from "react-spinners/PulseLoader";

const Products = () => {
  return (
    <div className="productsContainer">
      <div className="productsDescription">
        <div style={{ gridColumnStart: 2, gridColumnEnd: 3 }}>
          <h1>Vöruflokkar</h1>
          <h2>Skoðaðu brot af því sem við bjóðum uppá</h2>
          <h3>Vefsíðan er í viðhaldi, við þökkum fyrir þolinmæðina</h3>
        </div>
      </div>
    </div>
  );
};

export default Products;
