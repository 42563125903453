import React from "react";
import "./style.css";
import { useFirstPrismicDocument } from "@prismicio/react";
import opnunSumar1 from "/Users/bjornandri/Desktop/samasem/src/resources/OpenSummer1.png";
import { useState, useEffect } from "react";

const MainContentHome = () => {
  const [document] = useFirstPrismicDocument();
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    console.log("window resize", width);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  useEffect(() => {
    console.log("isMobile", isMobile);
  }, [isMobile]);

  return (
    <div className="MainParent">
      {document && (
        <div
          className="UpperMain"
          style={{
            backgroundImage: `url(${
              isMobile
                ? document.data.opening_picture_mobile.url
                : document.data.opening_picture.url
            })`,
          }}
        >
          {document.data.infotexthomepage[0].text && (
            <div className="infoText">
              <h1>{document.data.infotexthomepage[0].text}</h1>
            </div>
          )}
          {document.data.infoimagehomepage.url && (
            <img
              className="OpeningExtra"
              src={document.data.infoimagehomepage.url}
              alt=""
            />
          )}
          {document.data.opening_hours.url && (
            <img
              className="OpeningH"
              src={document.data.opening_hours.url}
              alt="OpeningHours"
            />
          )}
        </div>
      )}
    </div>
  );
};

export default MainContentHome;
