import "./style.css";

const SearchPage = () => {
  return (
    <div className="search-page-container">
      <div className="search-page-header">
        <h1>Vöruleit</h1>
      </div>
      <h3>Vefsíðan er í viðhaldi, við þökkum fyrir þolinmæðina</h3>
    </div>
  );
};
export default SearchPage;
